import React from "react"
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../components/theme'
import '../css/style.css'
import Nav from '../components/_nav'
import PageAlimento from '../components/page_alimentos_sidebar'
import Footer from '../components/footer'
import SEO from '../components/seo'
import { graphql } from 'gatsby'

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

const AlimentossPages = props => {

    const { pageContext } = props;
    const imgSrc = props.data.allImageSharp.edges[0].node.gatsbyImageData.images.fallback.src
    props.location.host = "infonutrientes.com.br"
    
    return (
        <ThemeProvider theme={theme}>

            <>
                <SEO
                    title={pageContext.nome}
                    description={pageContext.desc}
                    keywords={pageContext.kw}
                    canonical={`https://${props.location.host}/${pageContext.cat}`}
                    base_url={`https://${props.location.host}`}
                    post_date={"2021-03-15"}
                    post_cat={pageContext.slug}
                    post_cat_name={pageContext.slug === "miscelanea" ? "Miscelânea" : pageContext.slug.capitalize()}
                    post_slug={""}
                    post_slug_name={pageContext.nome}
                    post_descp_kps={pageContext.desckps}
                    nutrinfo={false}
                    location={props.location}
                    image={imgSrc}
                />
            </>

            <>
                <Nav />
            </>

            <main>
                <PageAlimento info={pageContext} {... props} />
            </main>

            <>
                <Footer />
            </>

        </ThemeProvider>
    )
}

export default AlimentossPages;

export const pageQuery = graphql`
    query ($img: String) {
        allImageSharp(filter: {original: {src: {regex: $img}}}) {
            edges {
                node {
                    gatsbyImageData
                }
            }
        }
    }
`;