import React, {useEffect, useCallback, useRef, useState} from 'react'
import ResizeObserver from "resize-observer-polyfill"
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import NutriFacts from './form/nutrifacts_static'
import XSNutriFacts from './form/xsnutrifacts_static'
import Hidden from '@material-ui/core/Hidden'
//import InformacoesData from '../../data/informacoes.json'
import rowsData from '../../data/all_data_obj_min'
import Box from '@material-ui/core/Box'
import SearchSidebar from './search_sidebar'
import SearchColumns from './search_columns'
import { GatsbyImage, getImage } from "gatsby-plugin-image"


String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

const useObserver = ({ callback, element }) => {
    // https://medium.com/@eymaslive/react-hooks-useobserve-use-resizeobserver-custom-hook-45ec95ad9844
    const current = element && element.current;
  
    const observer = useRef(null);
  
    useEffect(() => {
        // if we are already observing old element
        if (observer && observer.current && current) {
          observer.current.unobserve(current);
        }
        const resizeObserverOrPolyfill =  ResizeObserver;
        observer.current = new resizeObserverOrPolyfill(callback);
        observe();
  
        return () => {
          if (observer && observer.current && element &&
             element.current) {
            observer.current.unobserve(element.current);
          }
        };
    }, [current]);
  
    const observe = () => {
      if (element && element.current && observer.current) {
        observer.current.observe(element.current);
      }
    };
  
};

const Alimentos = (props) => {

    const reAlm = new RegExp(`^${props.info.key}`, "g")
    const rowsFiltered = rowsData.alimentos.filter(val => reAlm.test(val["3"]))
    const ascRowsFiltered = rowsFiltered.sort((a, b) => a["5"] - b["5"]).slice(0, 5)

    var rndKey = Math.floor(Math.random() * (rowsFiltered.length - 1)) << 0;
    const rndAlm = rowsFiltered[rndKey]

    const mainBoxRef = useRef(false)
    const boxTableRef = useRef(false)
    const boxSide1Ref = useRef(false)
    const boxSide2Ref = useRef(false)

    const toPX = (val) => (
        val.toString() + "px"
    )
    
    const someCallback = () => {
        if (boxTableRef.current.offsetWidth === mainBoxRef.current.offsetWidth) {
            mainBoxRef.current.style.height = "auto"
            //console.log(1, mainBoxRef.current.offsetHeight, mainBoxRef)
            return void(0)

        } else if (mainBoxRef.current.offsetWidth > boxTableRef.current.offsetWidth) {
            
            const sideH = boxSide1Ref.current.offsetHeight + boxSide2Ref.current.offsetHeight
            
            if (sideH > boxTableRef.current.offsetHeight) {
                mainBoxRef.current.style.height = toPX(sideH)
            } else {
                mainBoxRef.current.style.height = toPX(boxTableRef.current.offsetHeight)
            }
            
            //console.log(2, toPX(boxTableRef.current.offsetHeight), mainBoxRef)
            return void(0)
        
        } else {
            mainBoxRef.current.style.height = "auto"
            //console.log(3, mainBoxRef.current.offsetHeight, mainBoxRef)
            return void(0)
        }

    }
    
    const mainBoxObserver = useObserver({ callback: someCallback, element: boxTableRef })

    return (
        <>
        <Grid container direction="row" justify="center" alignItems="center" spacing={0} className={"pt-6"}>
            
            <Grid item xs={12} sm={12} md={12} lg={11} xl={10}>
            
                <Box ref={mainBoxRef}
                     className={"w-full flex flex-nowrap md:flex-wrap 2xl:flex-wrap flex-col 2xl:flex-col justify-start self-start items-center content-start"}
                >

                    <Box ref={boxSide1Ref} className={"w-full md:w-4/12 2xl:w-3/12 m-0 xl:mr-3 order-2 md:order-1 2xl:order-1"}>
                        <Paper elevation={3} className={"mx-1 xl:mx-0 mb-3 border-t-4 border-green-900"}>
                            <Paper elevation={0} className={'p-3 m-2'}>
                                
                                <SearchSidebar info={{"key": props.info.key}} />

                            </Paper>
                        </Paper>
                    </Box>

                    <Box ref={boxTableRef} className={"w-full md:w-8/12 2xl:w-6/12 m-0 xl:mr-3 order-1 md:order-2 2xl:order-2"}>
                        <Paper elevation={3} className={"mx-1 xl:mx-0 mb-3 border-t-4 border-green-900"}>
                            <Paper elevation={0} className={'p-3 m-2'}>

                                <h1 className={"p-3 mx-auto text-3xl text-center tracking-widest font-extrabold text-green-700"}>
                                    
                                    {
                                        (props.info.key === "miscelanea")
                                        ? ("Miscelânea")
                                        : (props.info.key.capitalize())
                                    }

                                    &nbsp;e seus derivados.

                                </h1>

                                {/* <p class="mb-2 font-normal text-lg text-gray-700 text-justify leading-loose" style={{textIndent:"5%"}}>
                                    200 Palavras. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dictum leo libero, vitae molestie arcu laoreet at. Phasellus vel ultricies est. Maecenas aliquam lorem nulla, id accumsan tortor cursus eu. Aliquam vitae metus consequat, vehicula lectus eget, fringilla ipsum. Sed mattis a lectus id consequat. Sed convallis mauris vel dui tristique, nec ultrices lectus varius. Nunc ipsum libero, facilisis at justo ac, elementum pulvinar purus. Sed sodales dictum nisi. Maecenas vestibulum magna nec ipsum interdum porttitor. Donec venenatis sodales est, non congue erat convallis quis. Nulla efficitur vehicula lacus, a sollicitudin sapien lacinia sed.
                                </p> */}

                                {/* <img
                                    className={"w-full max-h-80 rounded-md my-3 object-cover"}
                                    style={{objectPosition:"0 70%"}}
                                    src="https://www.foodallergycanada.ca/wp-content/uploads/milk-dairy-products.jpg"
                                    alt={"alimentos derivados do " + props.info.key}
                                /> */}

                                <GatsbyImage
                                    className={"w-full max-h-80 rounded-md my-3 object-cover"}
                                    objectPosition={"0% 70%"}
                                    image={getImage(props.data.allImageSharp.edges[0].node)}
                                    alt={"alimentos derivados do " + props.info.id}
                                    width={"100%"}
                                    height={"0"}
                                />

                                {/* <p class="mb-2 font-normal text-lg text-gray-700 text-justify leading-loose" style={{textIndent:"5%"}}>
                                    Pellentesque eu euismod tortor. Phasellus eros magna, blandit a nisi vel, rhoncus sagittis enim. Cras luctus id nisi id rutrum. Morbi vulputate vel sem nec pulvinar. Sed blandit tortor id interdum gravida. Mauris ipsum ante, consectetur ac lectus sit amet, varius faucibus diam. Nunc porttitor diam lobortis quam blandit, nec rutrum nisi faucibus. Duis leo massa, egestas ut magna quis, gravida congue dui. Nam tempor facilisis libero ut tempus. Fusce rutrum facilisis dui, a suscipit ligula pulvinar et. Curabitur euismod malesuada nunc in dictum. Fusce vel nisi aliquam, vehicula magna dapibus, ultrices magna. Donec sollicitudin euismod turpis vel rhoncus. Duis vestibulum, velit et pellentesque viverra, velit.
                                </p> */}

                                <p class="mb-2 font-normal text-lg text-gray-700 text-justify leading-loose" style={{textIndent:"5%"}}>
                                    Exemplo de alimento do grupo&nbsp;
                                    {
                                        (props.info.key === "miscelanea")
                                        ? ("miscelânea")
                                        : (props.info.key)
                                    }
                                </p>


                                <Hidden only={['xs', 'sm']}>
                                    <NutriFacts
                                        info={{"name": rndAlm["2"]}}
                                        data={rndAlm}
                                        //ad="https://via.placeholder.com/468x60?text=468x60"
                                        showTitle={true}
                                    />
                                </Hidden>
                                <Hidden only={['md', 'lg', 'xl']}>
                                    <XSNutriFacts
                                        info={{"name": rndAlm["2"]}}
                                        data={rndAlm}
                                        //ad="https://via.placeholder.com/200x200?text=468x60"
                                        showTitle={true}
                                    />
                                </Hidden>
                            </Paper>
                        </Paper>
                    </Box>

                    <Box ref={boxSide2Ref} className={"w-full md:w-4/12 2xl:w-3/12 m-0 order-3 md:order-1 2xl:order-3"}>
                        <Paper elevation={3} className={"mx-1 xl:mx-0 border-t-4 border-green-900"}>
                            <Paper elevation={0} className={'p-3 m-2'}>

                                {/* <h2 class="font-normal uppercase text-lg text-gray-900 text-center p-3 mb-3 bg-green-100 rounded"> */}
                                <h2 className={"p-3 mx-auto text-md tracking-wider font-normal uppercase text-center text-green-700"}>
                                    Alimentos do grupo {(props.info.cat === 'miscelanea') ? "miscelânea" : props.info.cat} <span class="font-black text-red-700">com a menor quantidade</span> de calorias (kcal)
                                </h2>

                                <List component="ul" className={"w-full"} aria-label="contacts">
                                    {
                                        ascRowsFiltered.map((fonte, idx) =>
                                        (
                                            <ListItem component="li" button>

                                                <a
                                                    href={"/" + fonte["3"] + "/" + fonte["2"].latinize().replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s+/g, "-").toLowerCase() + "/"}
                                                    class="inline-block"
                                                >
                                                    <ListItemIcon
                                                        classes={{ root: "font-black" }}
                                                    >
                                                        {idx + 1}.
                                                        </ListItemIcon>
                                                </a>


                                                <a
                                                    href={"/" + fonte["3"] + "/" + fonte["2"].latinize().replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s+/g, "-").toLowerCase() + "/"}
                                                    class="inline-block"
                                                >
                                                    <ListItemText
                                                        primary={fonte["2"]}
                                                        disableTypography
                                                        classes={{ root: "font-light text-md" }}
                                                    />
                                                </a>

                                            </ListItem>
                                        )
                                        )
                                    }
                                    <ListItem component="li">
                                        <a href="#data-alm" class="w-full text-right">
                                            <span class="font-light text-md p-2 m-1 hover:bg-green-100 rounded">
                                                ver mais <ArrowRightAltIcon />
                                            </span>
                                        </a>
                                    </ListItem>
                                </List>

                            </Paper>
                        </Paper>
                    </Box>


                </Box>

            </Grid>


            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>

                <Box className={"w-full mx-auto"}>

                    <Paper elevation={3} className={"mx-1 xl:mx-0 mb-3 border-t-4 border-green-700"}>
                        <Paper elevation={0} className={'p-3 m-2'}>

                            <h2 className={"p-3 mx-auto text-3xl text-center tracking-widest font-extrabold text-green-700"}>
                                Pesquise a <span class="font-black text-red-700 uppercase">composição nutricional</span> dos alimentos.
                                <br />
                                Atualmente temos 4077 alimentos cadastrados.
                            </h2>

                            <SearchColumns rows={rowsData.alimentos} />

                        </Paper>
                    </Paper>

                </Box>

            </Grid>



        </Grid>

        <Box 
            className={"bg-gray-800 pb-1 h-80 -mb-60 overflow-hidden"}
            style={{clipPath:"polygon(100% 25%, 0 25%, 100% 0)"}}
        >
        </Box>

        </>
    )
}

export default Alimentos;